
export default {
  name: 'MoleculeContentSlider',
  props: {
    carouselOpts: {
      required: false,
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      instance: null
    }
  },
}
