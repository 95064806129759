
export default {
  name: 'MoleculeContentSliderBrochure',
  data() {
    return {
      instance: null,
      options: {
        perPage: 1,
        type: 'loop',
        pagination: true,
        gap: '20px',
        autoplay: true,
        interval: 10000,
        arrows: false
      },
    }
  }
}
