
export default {
  name: 'MoleculeIconListWithTexts',
  props: {
    dataList: {
      required: true,
      type: Array,
      default: function () {
        return []
      }
    },
    mobileColumnNumber: {
      required: false,
      type: [Number, String],
      default: 6
    },
    centerContent: {
      required: false,
      type: Boolean,
      default: false
    },
    tabletColumnNumber: {
      required: false,
      type: [Number, String],
      default: 6
    },
    columnNumber: {
      required: false,
      type: [Number, String],
      default: 6
    }
  }
}
