import { render, staticRenderFns } from "./MoleculeContactForm.vue?vue&type=template&id=2f9271a2&"
import script from "./MoleculeContactForm.vue?vue&type=script&lang=js&"
export * from "./MoleculeContactForm.vue?vue&type=script&lang=js&"
import style0 from "./MoleculeContactForm.vue?vue&type=style&index=0&id=2f9271a2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/lang/MoleculeContactForm.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fcomponents%2Fmolecules%2Fcommon%2FMoleculeContactForm.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomsCommonAtomInput: require('/app/components/atoms/common/AtomInput.vue').default,AtomsCommonAtomCheck: require('/app/components/atoms/common/AtomCheck.vue').default,AtomsCommonAtomButton: require('/app/components/atoms/common/AtomButton.vue').default})
