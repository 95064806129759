
import {WebRequest} from "~/helpers/api";

export const EnumInputTypes = {
  TEXT: 'text',
  DATE: 'date',
  TEXTAREA: 'textarea',
  NUMBER: 'number',
  PASSWORD: 'password',
  TEL: 'tel'
}
export default {
  name: 'MoleculeContactForm',
  props: {
    disableCompanyNumber: {
      required: false,
      type: Boolean,
      default: false
    },
    disableCompanyAddress: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errors: {},
      companyNumber: '',
      companyName: '',
      firstName: '',
      lastName: '',
      companyAddress: '',
      phone: '',
      email: '',
      message: '',
      inputTypes: EnumInputTypes,
      buttonSendState: false
    }
  },
  computed: {
    client() {
      return this.$config.CLIENT
    },
    termsAndConditionsText() {
      return this.$store?.state?.base?.meta?.generalConfig?.termsAndConditionsText || '';
    },
    activeProject() {
      return this.$store.state.project.activeProject
    },
  },
  methods: {
    submitForm(e) {
      e.preventDefault()
      this.buttonSendState = true;
      if (this.validateInputs() !== null) return

      const project = this.$store.state.project?.projects?.find((p) => p.slug === this.activeProject) || -1;
      if(project === -1) return;
      const payload = {
        company_number: this.companyNumber === '' ? null : this.companyNumber,
        company_name: this.companyName,
        company_address: this.companyAddress === '' ? null : this.companyAddress,
        contact_firstname: this.firstName,
        contact_lastname: this.lastName,
        contact_phone_number: this.phone,
        contact_email: this.email,
        notes: this.message,
        project_id: project.id,
        status: 0,
      }

      if (window && 'dataLayer' in window) {
        window.dataLayer.push({
          event: 'contact_request_submit',
          category: 'contact_request',
          label: 'contact_request'
        })
      }

      if (window && 'analyticsLayer' in window) {
        window.analyticsLayer.send({
          event: 'contact_request_submit',
          payload
        })
      }
      WebRequest
        .POST(this.$webApi.landlord.contact(), payload)
        .then((res) => {
          if (res.error) {
            alert(res.error.message)
            return
          }
          this.$refs.contactForm.reset()
          if (this.$config.client === 'one' && this.$config.RUNTIME_ENV === 'production') {
            if ('gtag_report_conversion' in window) {
              window.gtag_report_conversion()
            }
            if ('lintrk' in window) {
              window.lintrk('track', { conversion_id: 4403868 })
            }
          }
          window.location.href = '/thank-you';
          this.$refs.check.checked = false
        })
        .catch((e) => {
          alert(e.message)
        })
    },

    validateInputs() {
      this.errors = {}
      if (this.companyName === '') {
        this.errors.companyName = 'companyRequired'
      }
      if (this.firstName === '') {
        this.errors.firstName = 'firstNameRequired'
      }
      if (this.lastName === '') {
        this.errors.lastName = 'lastNameRequired'
      }
      if (this.phone === '' || !this.phone.match(/^[+]?\d{10,13}$/)) {
        this.errors.phone = 'phoneNumberRequired'
      }
      if (!this.validEmail(this.email)) {
        this.errors.email = 'emailRequired'
      }
      if (!this.$refs.check.checked) {
        this.errors.agree = 'agreeRequired'
      }
      if (Object.keys(this.errors).length === 0) {
        return null
      }
      this.buttonSendState = false;
      return this.errors
    },

    validEmail: function (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
  }
}
